import React, { useState, useContext, useRef } from 'react'
import { Auth } from 'aws-amplify'
import {
  Menu,
  Title,
  Header,
  NavDropDownButton,
  ExtendedNav,
  NavMenuButton,
  Button,
}
from '@trussworks/react-uswds'
import { Link, useHistory } from 'react-router-dom'
import UserContext from '../components/UserContext'
import { IoPersonCircle as PersonIcon } from 'react-icons/io5'
import hasRole from '../services/hasRole'
import './DashboardNav.css'
import context from 'react-bootstrap/esm/AccordionContext'


const DashboardNav = () => {
  const [expanded, setExpanded] = useState(false)
  const onClick = () => setExpanded((prvExpanded) => !prvExpanded)
  const [isOpen, setIsOpen] = useState([false, false, false, false, false, false, false])
  const userContext = useContext(UserContext)
  const contextRef = useRef()
  const history = useHistory();
  contextRef.current = userContext

  //Set user info to be displayed by log out button
  const name = userContext.user.attributes.name.split(" ")[0];
  const DTORole = userContext.user.attributes['custom:dto'] ? " DTO: " + userContext.user.attributes['custom:dto'] : ''
  const operatorRole = userContext.user.attributes['custom:operator'] ? " Operator: " + userContext.user.attributes['custom:operator'] : ''


  // console.log(userContext)

  function onToggle(n) {
    let isOpenCopy = [false, false, false, false, false, false, false]

    if (isOpen[n] === false) {
      isOpenCopy[n] = true
    }
    else if (isOpen[n] === true) {
      isOpenCopy[n] = false
    }
    setIsOpen(isOpenCopy)
  }

  function signOut() {
    Auth.signOut()
      .then(() => {
        userContext.updateCurrentUser()
        history.push('/')
      })
      .catch((e) => console.log('error signing out...' + e))
  }

  const testMenuItems = [
    <></>,

  ]

  const documentMenuItems = [
    // <Link to="/faq-page" key="one">
    //   FAQ
    // </Link>,
    <Link to="/aboutAuth" key="two">
      About Us
    </Link>,
    <Link to="/glossary" key="three">
      Glossary of Terms
    </Link>,
    <Link to="/help" key="four">
      Help
    </Link>,
    <Link to="/documents" key="four">
      Additional Document Downloads
  </Link>
  ]
  const myFleetMenuItems = [
    <Link to="/fleet">
      Manage Fleet / Upload Flight
    </Link>,
  ]

  const myFlightsMenuItems = [
    <Link to='/my-flights' key="one">
      My Flights
    </Link>,
  ]

  const homeMenuItems = [
    <Link to='/home' key="one">
      Home
    </Link>,
  ]

  const metricsMenuItems = [
    <Link hidden={hasRole(contextRef.current, ['dto'])} to="/metrics" key="one">
      Operator Aggregates
    </Link>,
    <Link hidden={process.env.REACT_APP_USER_BRANCH === 'prod' || process.env.REACT_APP_USER_BRANCH === 'dev'} to="/unstappscore" key="six">
      Unstable Approach
    </Link>,
    <Link hidden={hasRole(contextRef.current, ['dto'])} to="/user-metrics" key="three">
      UAS Sightings
    </Link>,
    <Link hidden={hasRole(contextRef.current, ['dto'])} to="/uimc-metrics" key="four">
      UIMC Analysis
    </Link>,
    <Link hidden={hasRole(contextRef.current, ['dto'])} to="/arrdep" key="five">
      Arrivals/Departures
    </Link>,
    <Link hidden={process.env.REACT_APP_USER_BRANCH === 'prod' || hasRole(contextRef.current, ['dto'])} to="/approach-metrics" key="six">
      Approach Insights
   </Link>,
    <Link hidden={!(hasRole(contextRef.current, ['dto']) || hasRole(contextRef.current, ['operator']))} to="/fileStatus" key="seven">
      File Upload Status
  </Link>,
  ]


  const geoSearchMenuItems = [
    <Link hidden={process.env.REACT_APP_USER_BRANCH !== 'dev'} to="/search_by_helipads" key="one">
      Search Flights by Helipads
    </Link>
  ]

  const adminMenuItems = [
    <Link to='/user_management' key="one">
      User Management
    </Link>
  ]



  const itemsMenu = [
    <>
      <Link to='/home' style={{ padding: '0.5rem' }}>
        <Button type="button">
          Home </Button>
      </Link>

      {/* <NavDropDownButton
          onToggle={() => {
            onToggle(0)
          }}
          menuId="home"
          isOpen={isOpen[0]}
          label="Home"
          isCurrent={isOpen[0]}
        /> */}
      {/* <Menu
          key="one"
          items={homeMenuItems}
          isOpen={isOpen[0]}
          id="homeDropDown"
        /> */}
      </>,
    <>
      {(hasRole(contextRef.current, ['administrator']) 
      || hasRole(contextRef.current, ['operator']) 
      || hasRole(contextRef.current, ['r-iat'])
      || hasRole(contextRef.current, ['dto'])
      ) &&
      <>
            <div style={{padding: '0.5rem'}}>
        <NavDropDownButton
          style={{}}
          onToggle={() => {
            onToggle(4)
          }}
          menuId="metrics"
          isOpen={isOpen[4]}
          label="Metrics"
          isCurrent={isOpen[4]}
        />
        <Menu
          key="one"
          items={metricsMenuItems}
          isOpen={isOpen[4]}
          id="dropDownFour"
        />
        </div>
        </>
      }
      </>,
    <>
  {(hasRole(contextRef.current, ['administrator']) || hasRole(contextRef.current, ['operator'])) &&
  <>
  <Link to='/my-flights' style={{padding: '0.5rem'}}>
          <Button type="button">
            My Flights </Button>
        </Link>
    {/* <NavDropDownButton
      onToggle={() => {
        onToggle(3)
      }}
      menuId="myFlights"
      isOpen={isOpen[3]}
      label="My Flights"
      isCurrent={isOpen[3]}
    />
    <Menu
      key="one"
      items={myFlightsMenuItems}
      isOpen={isOpen[3]}
      id="dropDownThree"
    /> */}
    </>
  }
  </>,
    <>
    {(hasRole(contextRef.current, ['administrator']) || hasRole(contextRef.current, ['operator']) || hasRole(contextRef.current, ['dto'])) &&
  <>
    <Link to='/fleet' style={{padding: '0.5rem'}}>
          <Button type="button">
            Manage Fleet / Upload Flight </Button>
        </Link>
    {/* <NavDropDownButton
      onToggle={() => {
        onToggle(2)
      }}
      menuId="myFleet"
      isOpen={isOpen[2]}
      label="My Fleet"
      isCurrent={isOpen[2]}
    />
    <Menu
      key="one"
      items={myFleetMenuItems}
      isOpen={isOpen[2]}
      id="dropDownTwo"
    /> */}
    </>
    }
  </>,

    <div style={{padding: '0.5rem'}}>
  <NavDropDownButton
  style={{}}
    onToggle={() => {
      onToggle(1)
    }}
    menuId="documents"
    isOpen={isOpen[1]}
    label="Documents"
    isCurrent={isOpen[1]}
  />
  <Menu
    key="one"
    items={documentMenuItems}
    isOpen={isOpen[1]}
    id="dropDownOne"
  />
</div>,
    <>
    {/*<NavDropDownButton
      onToggle={() => {
        onToggle(5)
      }}
      menuId="r-iat-metrics"
      isOpen={isOpen[5]}
      label="R-IAT Metrics"
      isCurrent={isOpen[5]}
    />
    <Menu
      key="one"
      items={metricsMenuItems}
      isOpen={isOpen[5]}
      id="testDropDownOne"
    />*/}
    </>,
    <>
      {(hasRole(contextRef.current, ['administrator']) || hasRole(contextRef.current, ['approver'])) &&
        <>
          <Link to='/user_management' style={{ padding: '0.5rem' }}>
            <Button type="button">
              User Management </Button>
          </Link>
          {/* <NavDropDownButton
            onToggle={() => {
              onToggle(6)
            }}
            menuId="adminSettings"
            isOpen={isOpen[6]}
            label="Admin Settings"
            isCurrent={isOpen[6]}
          />
          <Menu
            key="one"
            items={adminMenuItems}
            isOpen={isOpen[6]}
            id="testDropDownOne"
          /> */}
        </>
      }
    </>,

    <>
      {/* <Link to='/upload' style={{padding: '0.5rem'}}>
          <Button type="button"
            className="upload-button"
            style={{width: '160px', backgroundColor: '#005EA2',
            textAlign: 'center', color: 'white', borderRadius: '4px'}}>
            Upload a flight </Button>
        </Link> */}
    </>
  ]




  return (
    <>
      <div className={`usa-overlay ${expanded ? 'is-visible' : ''}`}></div>
      <Header extended={true}>
        <div style={{ position: "relative", height: "auto" }} className="usa-navbar">
          <Link to='/home'>
            <img
              src={process.env.PUBLIC_URL + '/img/asiasLogo.png'}
              style={{ width: '320px', height: '80px' }}>
            </img>
            {/*<Title style={{float: 'left', color: 'black'}}>ASIAS</Title>*/}
          </Link>
          <div className="account-link-div">
            {DTORole && operatorRole ? DTORole + ' /' : DTORole} {operatorRole} <br />
            <Link to='/settings/profile' className="account-link">
              <span style={{ fontSize: '1rem' }}>
                <PersonIcon style={{ fontSize: '1.5em' }} />
                Welcome, {name}
              </span>
            </Link>
            <Button type="button" outline onClick={signOut} style={{ padding: '.5rem 1rem', marginLeft: '5px' }}>
              Sign out
            </Button>
          </div>
          <NavMenuButton style={{ position: "absolute", right: "0px", height: "-webkit-fill-available" }} onClick={onClick} label="Menu" />
        </div>
        <div>
          <ExtendedNav
            primaryItems={itemsMenu}
            secondaryItems={testMenuItems}
            mobileExpanded={expanded}
            onToggleMobileNav={onClick}>
          </ExtendedNav>
        </div>
      </Header>
    </>
  )
}

export default DashboardNav
